<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('roles.all_roles') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <DatatableDropdown />
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link to="/roles/new" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t('roles.add_role') }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class='card-body'>
            <div v-if="isLoading">
              <table class="table table-striped table-bordered collapsed mt-20" style="width:100%" >
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('commons.role') }}</th>
                  <th scope="col">{{ $t('commons.institution') }}</th>
                  <th scope="col">{{ $t('commons.operations') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in 10" :key="'loading-' + index">
                  <th scope="row"><Skeleton height="38px" /></th>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                </tr>
                </tbody>
              </table>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
              <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t('commons.role') }}</th>
                    <th scope="col">{{ $t('commons.institution') }}</th>
                    <th scope="col">{{ $t('commons.operations') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in roleList" :key="'role-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.name }}</td>
                    <td>{{ item.institution_id }}</td>
                    <td>
                        <router-link v-if="checkPermission('crm.authority.roles.edit')" :to="`/roles/update/${item.id}`" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.edit')">
                            <i class="la la-pencil"></i>
                        </router-link>
                        <button v-if="checkPermission('crm.authority.roles.destroy')" type="button" @click="deleteRole(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.delete')">
                            <i class="la la-trash"></i>
                        </button>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import $ from "jquery";
import Swal from 'sweetalert2'
import { Skeleton } from 'vue-loading-skeleton';
import DatatableDropdown from '@/components/datatable/Dropdown.vue'


export default {
  name: 'RoleList',
  data() {
    return {
      isLoading:true
    }
  },
  components: {
    Breadcrumb,
    Skeleton,
    DatatableDropdown
  },
  methods: {
    deleteRole(id) {
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel')
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('roles/DELETE_ROLES', id)
        }
      });
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
  },
  computed: {
    ...mapState({
      roleList: state => state.roles.roleList,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          this.$t('roles.title')
        ]
      }
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.authority.roles.index')) {
      this.$router.push('/no-permission')
    }
    const self = this;
    self.$store.dispatch('roles/GET_ROLE_LIST').then(function () {
      self.isLoading = false;
      self.$nextTick(function (){
        var fileName = 'Role';
        $(self.$refs['kt_datatable']).DataTable({
          responsive: true,
          order: [[ 0, 'desc' ]],
          pagingType: 'full_numbers',
          dom: 'Blfrtip',
          buttons: ['copy', 'csv', 'excel', 'pdf', 'print'].map(el => (
            {
              extend: el,
              filename: fileName
            }
          )),
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          pageLength: 10,
          initComplete: function() {
            $('.dt-buttons').hide();
          },
          columnDefs: [
            { orderable: false, targets: -1 },
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
              url: `/assets/languages/datatable/${this.lang}.json`
          }
        });
        $("#printExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-print").trigger("click");
        });
        $("#copyExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-copy").trigger("click");
        });
        $("#excelExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-excel").trigger("click");
        });
        $("#csvExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-csv").trigger("click");
        });
        $("#pdfExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-pdf").trigger("click");
        });
      })
    })
  },
}
</script>
<style lang="scss">
</style>
